import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Profile from "../pages/profile";
import Receiver from "../pages/receiver";
import Express from "../pages/express";
import Deliver from "../pages/deliver";
import Rider from "../pages/rider";
import DetectOrder from "../pages/detectOrder";
import OfflineCustomer from "../pages/offlineCustomer";
import Location from "../pages/location";
import AllOrder from "../pages/allOrder";
import NotFoundPage from "../pages/404";
import UserList from "../pages/user/list";
import UserForm from "../pages/user/form";
import ServicesList from "../pages/services/list";
import ServicesForm from "../pages/services/form";
import ItemsList from "../pages/items/list";
import ItemsForm from "../pages/items/form";

const DashboardRoutes = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            type="test"
            is_access={props?.user_data?.menu_permissions?.Dashboard}
          />
        }
      />
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/receiver"
        element={
          <Receiver is_access={props?.user_data?.menu_permissions?.Receiver} />
        }
      />
      <Route
        path="/deliver"
        element={
          <Deliver is_access={props?.user_data?.menu_permissions?.Deliver} />
        }
      />
      <Route
        path="/rider"
        element={
          <Rider is_access={props?.user_data?.menu_permissions?.Rider} />
        }
      />
      <Route
        path="/express"
        element={
          <Express is_access={props?.user_data?.menu_permissions?.Express} />
        }
      />
      <Route
        path="/detect-order"
        element={
          <DetectOrder
            is_access={props?.user_data?.menu_permissions?.Detect_order}
          />
        }
      />
      <Route
        path="/order/:id"
        element={
          <OfflineCustomer
            is_access={props?.user_data?.menu_permissions?.Offline_customer}
          />
        }
      />
      <Route
        path="/location"
        element={
          <Location is_access={props?.user_data?.menu_permissions?.Location} />
        }
      />
      <Route
        path="/order"
        element={
          <AllOrder is_access={props?.user_data?.menu_permissions?.Order} />
        }
      />
      <Route
        path="/user/list"
        element={
          <UserList is_access={props?.user_data?.menu_permissions?.Order} />
        }
      />
      <Route
        path="/user/form/:id"
        element={
          <UserForm is_access={props?.user_data?.menu_permissions?.Order} />
        }
      />

      <Route
        path="/services/list"
        element={
          <ServicesList is_access={props?.user_data?.menu_permissions?.Order} />
        }
      />
      <Route
        path="/services/form/:id"
        element={
          <ServicesForm is_access={props?.user_data?.menu_permissions?.Order} />
        }
      />

      <Route
        path="/items/list"
        element={
          <ItemsList is_access={props?.user_data?.menu_permissions?.Order} />
        }
      />
      <Route
        path="/items/form/:id"
        element={
          <ItemsForm is_access={props?.user_data?.menu_permissions?.Order} />
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default DashboardRoutes;

import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import ToastContext from "../../components/toast/toastContext";
import Loader from "../../components/loader";
import Confirmation from "../../components/confirmation";
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import moment from "moment/moment";
import BarcodeReader from "react-barcode-reader";
import Barcode from "react-barcode";
import order from "../../apis/order";
import Checkbox from "@mui/material/Checkbox";
import verifyImg from "../../assets/icons/verify.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

const Order = (props) => {
  const {
    item,
    reloadData,
    open,
    setOpen,
    selectedOrder,
    setSelectedOrder,
    rider = [],
  } = props;

  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);
  const [spinner, setSpinner] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [selectedBarCode, setSelectedBarCode] = useState([]);
  const [verifiedBarcode, setVerifiedBarCode] = useState([]);
  const [selectedRider, setSelectedRider] = useState("");
  const [openRider, setOpenRider] = useState(false);
  const [showModal, setShowModel] = useState({
    show: false, // initial values set to false and null
    id: null,
    title: "Order Confirm",
    message: "",
  });

  useEffect(() => {}, []);

  const toggle = () => {
    setOpen(!open);
    setSelectedOrder(null);
  };

  const toggleRider = () => {
    setOpenRider(!openRider);
  };

  const getServiceType = (type) => {
    let name = "";
    switch (type) {
      case "WASH_AND_IRON":
        name = "WI";
        break;
      case "WASH_AND_FOLD":
        name = "WF";
        break;
      case "IRON_AND_FOLD":
        name = "IF";
        break;
      case "DRY_CLEAN":
        name = "DC";
        break;
      default:
        name = "NA";
    }
    return name;
  };

  const showOrderDetail = (itm) => {
    setSelectedOrder(itm);
    let arr = [];
    setVerifiedBarCode([]);
    if (itm?.is_barcode) {
      let arr = [];
      itm?.services?.map((x) => {
        x.barcode?.map((b) => {
          arr.push({
            barcode: b,
            type: getServiceType(x?.type),
          });
          return true;
        });
        return true;
      });
      setSelectedBarCode(arr);
    }
    setOpen(true);
  };

  let options = {
    width: 1.2,
    height: 30,
    format: "CODE128",
    displayValue: true,
    // font: "monospace",
    // fontOptions: "bold",
    fontSize: 21,
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 4,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 10,
  };

  const generateBarCode = async () => {
    try {
      setSpinner(true);
      const response = await order.generateOrderBarCode(selectedOrder?._id);
      if (response?.data?.data) {
        setSelectedOrder(response?.data?.data);
        let arr = [];
        response?.data?.data?.services?.map((x) => {
          x.barcode?.map((b) => {
            arr.push({
              barcode: b,
              type: getServiceType(x?.type),
            });
            return true;
          });
          return true;
        });
        setSelectedBarCode(arr);
      }
      reloadData();
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const onConfirmOrderReady = async () => {
    setShowModel({
      show: true,
      id: null,
      title: "Order Confirm!",
      message:
        "Make sure all barcode attached with cloths according to services, you cannot rollback this order ?",
    });
  };

  const onPrintBarcode = () => {
    var container = document.getElementById("div-svg");
    var width = "1000";
    var height = "600";
    var printWindow = window.open(
      "",
      "PrintMap",
      "width=" + width + ",height=" + height
    );
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const getOrderClass = (drop_date, drop_time_start) => {
    const todayStart = moment().startOf("day");
    const todayEnd = moment().endOf("day");
    if (new Date(drop_date).getTime() < new Date(todayStart).getTime()) {
      return "expire-drop-order";
    } else if (
      new Date(drop_date).getTime() >= new Date(todayStart).getTime() &&
      new Date(drop_date).getTime() <= new Date(todayEnd).getTime()
    ) {
      const year = new Date(drop_date).getFullYear();
      const month = new Date(drop_date).getMonth(); // Month is 0-based, so January is 0
      const day = new Date(drop_date).getDate();
      let dropTime = "00:00";
      if (drop_time_start.includes("PM")) {
        if (drop_time_start == "12PM") {
          dropTime = parseInt(drop_time_start.replace("PM", "")) + ":00";
        } else {
          dropTime = 12 + parseInt(drop_time_start.replace("PM", "")) + ":00";
        }
      } else {
        dropTime = parseInt(drop_time_start.replace("AM", "")) + ":00";
      }
      const [hours, minutes] = dropTime.split(":").map(Number);
      const selectedDate = new Date(year, month, day, hours, minutes);
      const currentDate = new Date();
      const timeDifference = Math.abs(selectedDate - currentDate);
      const threeHoursInMillis = 4 * 60 * 60 * 1000;
      if (timeDifference < threeHoursInMillis) {
        return "today-drop-order";
      }
      return "today-drop-order";
    }
    return "";
  };

  const finalConfirmOrder = async () => {
    try {
      setSpinner(true);
      await order.readyOrder(selectedOrder?._id);
      setSpinner(false);
      toastMessage("success", "Order successfully ready for service!");
      setOpen(false);
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const hideConfirmationModal = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
  };

  const submitConfirmed = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
    if (selectedOrder?.status === "READY_FOR_SERVICE") {
      returnConfirmed();
    } else {
      finalConfirmOrder();
    }
  };

  const returnConfirmed = async () => {
    try {
      setSpinner(true);
      await order.readyForReturnOrder(selectedOrder?._id);
      setSpinner(false);
      toastMessage("success", "Order successfully ready for return!");
      setOpen(false);
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const onConfirmOrderReturnReady = async () => {
    setShowModel({
      show: true,
      id: null,
      title: "Order Confirm!",
      message:
        "Make sure all items are available for this order and successfully packed ?",
    });
  };

  const handleDownload = async () => {
    try {
      if (downloadLoader) {
        return;
      }
      setDownloadLoader(true);
      const response = await order.downloadInvoice(selectedOrder?._id);
      const resultByte = response?.data?.pdf?.data;
      var bytes = new Uint8Array(resultByte);
      var blob = new Blob([bytes], { type: "application/pdf" });

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Order-Invoice-${selectedOrder?.order_id}.pdf`;
      link.click();
      setDownloadLoader(false);
      toastMessage("success", "Invoice downloaded!");
    } catch (err) {
      console.log(err);
      setDownloadLoader(false);
    }
  };

  const handleScan = (data) => {
    const barcode = data?.toString();
    console.log(barcode);
    const ind = verifiedBarcode.findIndex((x) => x == barcode);
    const ind1 = selectedBarCode.findIndex((x) => x.barcode == barcode);
    if (ind == -1) {
      if (ind1 > -1) {
        verifiedBarcode.push(barcode);
        setVerifiedBarCode([...verifiedBarcode]);
        toastMessage("success", "Success!");
      } else {
        toastMessage("error", "Invalid barcode!");
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const isBarCodeVerified = (b) => {
    let ind = verifiedBarcode.findIndex((x) => x == b);
    if (ind > -1) {
      return true;
    }
    return false;
  };

  const quickAssignHandle = (event) => {
    event.stopPropagation();
    setOpenRider(true);
  };

  const quickAssignConfirm = async () => {
    try {
      if (!selectedRider) {
        return toastMessage("error", "Rider is required");
      }
      setSpinner(true);
      const response = await order.quickAssign(
        selectedOrder?._id,
        selectedRider
      );
      reloadData();
      toggleRider();
      setSpinner(false);
      toastMessage("success", "Order assigned to Rider");
    } catch (err) {
      setSpinner(false);
    }
  };

  const editOrder = (id) => {
    navigate("/order/" + id);
  };

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      <div style={{ position: "relative" }}>
        {props?.type == "requested" && (
          <Checkbox
            checked={item?.checked}
            style={{
              marginTop: "-11px",
              marginLeft: "-11px",
              position: "absolute",
              background: "white",
              padding: 0,
            }}
            onChange={(e) => {
              // expressPickOrder[index].checked = e.target.checked;
              props.onChangeCheckboxValue(e.target.checked, item?._id);
            }}
          />
        )}
        <div
          className={`order-row ${getOrderClass(
            item?.drop_date,
            item?.drop_time_start
          )}`}
          onClick={() => {
            showOrderDetail(item);
          }}
        >
          <p>
            <label className="order-tag">
              #{item?.tag_no || "NA"}{" "}
              {item?.status !== "INITIATE" && (
                <i
                  className="fa fa-pencil-square-o icon-edit-btn"
                  style={{
                    cursor: "pointer",
                    color: "green",
                    fontSize: "20px",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    editOrder(item?._id);
                  }}
                  title="edit record"
                />
              )}
            </label>
            <label className="order-created">
              {moment(item?.createdAt).format("DD-MMM-YYYY")}
            </label>
          </p>
          <p>
            <label className="order-delivery">
              Customer Name:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.customer?.name || ""}
              </span>
            </label>
            <label className="order-qty">
              Mobile No:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.customer?.mobile_no
                  ? "+" + item?.customer?.mobile_no
                  : ""}
              </span>
            </label>
          </p>
          <p>
            <label className="order-delivery">
              Delivery Date:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {moment(item?.drop_date).format("DD-MMM-YYYY")}{" "}
                {item?.drop_time_slot}
              </span>
            </label>
            <label className="order-qty">
              Qty:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.quantity || 0}
              </span>
            </label>
          </p>
          <p className="order-type">
            <label>
              Order Type:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.type}
              </span>
            </label>
            <label className="order-qty">
              Order ID:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.order_id || "NA"}
              </span>
            </label>
          </p>
          <p className="order-message">
            Message:- {item?.comment || "No message from customer"}
            <label style={{ float: "right" }}>{item?.status}</label>
          </p>
          {(!item?.delivery_id || item?.status == "READY_FOR_RETURN") && (
            <p style={{ textAlign: "right" }}>
              <Button
                className="quick-assign-btn"
                onClick={(e) => {
                  setSelectedOrder(item);
                  quickAssignHandle(e);
                }}
              >
                Quick Assign to Rider
              </Button>
            </p>
          )}
          {/* {item?.delivery_id && item?.status != "READY_FOR_RETURN" && (
            <p style={{ textAlign: "right" }}>
              <Button
                className="quick-assign-btn"
                onClick={(e) => {
                  editOrder(item?._id);
                }}
              >
                Edit Order
              </Button>
            </p>
          )} */}
        </div>
      </div>

      {selectedOrder && selectedOrder?.tag_no === item?.tag_no && (
        <Modal size="lg" className="ledger-modal" isOpen={open} toggle={toggle}>
          <ModalHeader className="order-detail-modal-header">
            Order Details for {selectedOrder?.order_id || "NA"}
            {selectedOrder?.amount && (
              <span
                className={`download-invoice ${
                  downloadLoader ? "download-invoice-loader" : ""
                }`}
                onClick={handleDownload}
              >
                {!downloadLoader ? "Download Invoice" : "Downloading..."}
              </span>
            )}
          </ModalHeader>
          <ModalBody className="order-detail-outer">
            <BarcodeReader
              onError={handleError}
              onScan={(e) => {
                handleScan(e);
              }}
            />
            <label className="main-heading">BASIC DETAIL</label>
            <Row>
              <Col>
                <label className="heading">Tag No</label>
                <p className="heading-val">{selectedOrder?.tag_no}</p>
              </Col>
              <Col>
                <label className="heading">Type</label>
                <p className="heading-val">{selectedOrder?.type}</p>
              </Col>
              <Col>
                <label className="heading">Delivery Date & Time</label>
                <p className="heading-val">
                  {moment(selectedOrder?.drop_date).format("DD-MMM-YYYY")}
                </p>
                <p className="heading-val">{selectedOrder?.drop_time_slot}</p>
              </Col>
              <Col>
                <label className="heading">Amount & Quantity</label>
                <p className="heading-val">
                  INR {selectedOrder?.amount ? selectedOrder?.amount.toFixed(2) : "00.00" }
                </p>
                <p className="heading-val">{selectedOrder?.quantity || 0} Items</p>
              </Col>
            </Row>
            <label className="main-heading">CUSTOMER DETAIL</label>
            <Row>
              <Col>
                <label className="heading">Name</label>
                <p className="heading-val">
                  {selectedOrder?.customer?.name || "NA"}
                </p>
              </Col>
              <Col>
                <label className="heading">Mobile No</label>
                <p className="heading-val">
                  {selectedOrder?.customer?.mobile_no || "NA"}
                </p>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <label className="heading">Message</label>
                <p className="heading-val">
                  {selectedOrder?.comment || "No message from customer"}
                </p>
              </Col>
            </Row>
            <hr />
            <label className="main-heading">SERVICE DETAIL</label>
            <Row>
              <Col>
                <label className="heading">Name</label>
              </Col>
              <Col>
                <label className="heading">Quantity</label>
              </Col>
              <Col>
                <label className="heading">Weight</label>
              </Col>
            </Row>
            {selectedOrder?.services &&
              selectedOrder?.services.map((item) => {
                return (
                  <Row>
                    <Col md={4}>
                      <p className="heading-val">{item?.name}</p>
                    </Col>
                    <Col md={4}>
                      <p className="heading-val">{item?.quantity} Items</p>
                    </Col>
                    <Col md={4}>
                      <p className="heading-val">
                        {!item?.selected_items
                          ? `${Number(item?.weight).toFixed(2)} Kg`
                          : "NA"}
                      </p>
                    </Col>
                    {item?.selected_items && (
                      <Row style={{ marginBottom: "15px" }}>
                        {item?.selected_items?.map((b) => {
                          return (
                            <>
                              <Col md={4}>
                                <p
                                  className="heading-val"
                                  style={{ fontSize: "12px" }}
                                >
                                  {b?.name}: {b?.quantity} Item
                                </p>
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    )}
                    <Row style={{ marginBottom: "15px" }}>
                      {selectedOrder?.is_barcode &&
                        item.barcode?.map((b) => {
                          return (
                            <span className="barcode-label">
                              <Barcode {...options} value={b} />
                              {isBarCodeVerified(b) && (
                                <img
                                  className="verify-img"
                                  src={verifyImg}
                                  alt="verify"
                                />
                              )}
                            </span>
                          );
                        })}
                    </Row>
                  </Row>
                );
              })}

            {selectedBarCode && selectedBarCode?.length > 0 && (
              <Row id="div-svg" style={{ visibility: "visible" }}>
                {selectedBarCode?.map((b, index) => {
                  return (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "15px 0",
                        borderBottom: "0.8px dashed black",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {selectedOrder?.customer?.name || "NA"}
                      </p>
                      <Barcode {...options} value={b?.barcode} />
                      <p
                        style={{
                          fontSize: "10px",
                          margin: 0,
                          padding: 0,
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {moment().format("DD-MMM-YYYY")}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Garments {index + 1}/{selectedBarCode?.length} (
                        {b?.type})
                      </p>
                    </div>
                  );
                })}
              </Row>
            )}

            {selectedOrder?.status === "RECEIVED_BY_WAREHOUSE" &&
              !selectedOrder?.is_barcode && (
                <Button
                  className="confirm-btn"
                  style={{ float: "right" }}
                  onClick={() => {
                    generateBarCode();
                  }}
                >
                  Generate Bar Code
                </Button>
              )}

            {selectedOrder?.status === "RECEIVED_BY_WAREHOUSE" &&
              selectedOrder?.is_barcode && (
                <Button
                  className="confirm-btn"
                  style={{ float: "right" }}
                  onClick={() => {
                    onConfirmOrderReady();
                  }}
                >
                  Confirm Ready
                </Button>
              )}

            {selectedOrder?.status === "READY_FOR_SERVICE" && (
              <Button
                className="confirm-btn"
                style={{ float: "right" }}
                onClick={() => {
                  onConfirmOrderReturnReady();
                }}
              >
                Confirm Ready
              </Button>
            )}

            {selectedOrder?.is_barcode && (
              <Button
                className="print-btn"
                style={{ float: "right" }}
                onClick={() => {
                  onPrintBarcode();
                }}
              >
                Print BarCode
              </Button>
            )}
          </ModalBody>
        </Modal>
      )}

      {selectedOrder && selectedOrder?.tag_no === item?.tag_no && (
        <Modal
          size="lg"
          className="ledger-modal"
          isOpen={openRider}
          toggle={toggleRider}
        >
          <ModalHeader className="order-detail-modal-header">
            Order Details for {selectedOrder?.order_id || "NA"}
          </ModalHeader>
          <ModalBody className="order-detail-outer">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Rider</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedRider}
                label="Rider"
                onChange={(e) => {
                  setSelectedRider(e.target.value);
                }}
                style={{ background: "#e2e5ea" }}
              >
                {rider &&
                  rider.map((x) => {
                    return (
                      <MenuItem key={x?._id} value={x?._id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <Button
              className="print-btn"
              style={{ float: "right", marginTop: "15px" }}
              onClick={() => {
                quickAssignConfirm();
              }}
            >
              Submit
            </Button>
          </ModalBody>
        </Modal>
      )}

      {selectedOrder && selectedOrder?.tag_no === item?.tag_no && (
        <Confirmation
          data={showModal}
          confirmModal={submitConfirmed}
          hideModal={hideConfirmationModal}
        />
      )}
    </>
  );
};

export default Order;

import React, { useEffect, useState, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import api from "../../../apis/api";
import "./index.css";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ToastContext from "../../../components/toast/toastContext";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ItemsForm(props) {
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);

  const authData = useSelector((state) => state.auth);

  const { id } = useParams("id");
  const [data, setData] = React.useState({
    name: "",
    country_code: "91",
    mobile_no: "91",
    email_id: "",
    role: "",
    vehicle_id: "",
    vehicle_number: "",
  });
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [update, setupdate] = useState(false);

  useEffect(() => {
    if (id == -1) {
      setData({
        name: "",
      });
      // getItemsLocation();
    } else {
      api.get(`/item/${id}`).then((response) => {
        setData(response?.data?.data || {});
      });
    }
  }, []);

  const save = async () => {
    setData({ ...data });
    data.warehouse_id = authData?.user_data?.warehouse_id;
    if (id == -1) {
      api
        .post(`/item`, data)
        .then((response) => {
          setupdate(!update);
          toastMessage("success", "Item Added Successfully.");
          navigate("/items/list");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toastMessage(
            "error",
            err?.response?.data?.message || "Something went wrong!"
          );
        });
    } else {
      api
        .put(`/item/${id}`, data)
        .then((response) => {
          setupdate(!update);
          toastMessage("success", "Item Updated Successfully.");
          navigate("/items/list");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toastMessage(
            "error",
            err?.response?.data?.message || "Something went wrong!"
          );
        });
    }
  };

  const closeGoogleMap = () => {
    setShowMap(false);
  };

  const onChangeInput = (val, key) => {
    setData({
      ...data,
      [key]: val,
    });
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="right-head-section">
        <h6 className="page-heading">Items Form</h6>
      </div>
      <div className="outer-section" style={{ width: "100%" }}>
        <div className="merchant_list_main_div">
          <div style={{ padding: "15px" }}>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : (
              <>
                <Grid container spacing={2} className="merchant_main_grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Name</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Name"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.name}
                      required
                      onChange={(e) => onChangeInput(e.target.value, "name")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Amount</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Amount"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.amount}
                      required
                      type="number"
                      onChange={(e) => onChangeInput(e.target.value, "amount")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Gender</p>
                    <FormControl fullWidth size="small">
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={data?.gender || ""}
                        label="gender"
                        fullWidth
                        onChange={(e) =>
                          onChangeInput(e.target?.value, "gender")
                        }
                      >
                        <MenuItem value="both">Both</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Sequence</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Sequence"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.sequence}
                      required
                      type="number"
                      onChange={(e) => onChangeInput(e.target.value, "sequence")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Status</p>
                    <FormControl fullWidth size="small">
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={data?.status || ""}
                        label="gender"
                        fullWidth
                        onChange={(e) =>
                          onChangeInput(e.target?.value, "status")
                        }
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}

            {!enablemerchant && !loading ? (
              <div className="merchant_edit_button_dev">
                <Link to={`/items/list`}>
                  <Button
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  style={{ marginLeft: "15px" }}
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  Update
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div>
          <BootstrapDialog
            className="google-address-dialog"
            onClose={closeGoogleMap}
            aria-labelledby="customized-dialog-title"
            open={showMap}
          >
            <DialogContent>
              {/* <GoogleAddress
                onSubmitAddress={onSubmitAddress}
                center={center}
                setCenter={setCenter}
              /> */}
            </DialogContent>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
}

export default ItemsForm;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { unstable_styleFunctionSx } from "@mui/system";
import TextField from "@mui/material/TextField";
import "./index.css";
import TablePagination from "@mui/material/TablePagination";
import api from "../../../apis/api";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import serachIcon from "../../../assets/icons/search.svg";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { useSelector } from "react-redux";

const Div = styled("div")(unstable_styleFunctionSx);

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// main function

const ServicesList = (props) => {
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [spinner, setSpinner] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selected, setSelected] = useState(null);

  const authData = useSelector((state) => state.auth);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getData = () => {
    setSpinner(true);
    api
      .get(
        `/laundryServices?pageNo=` +
          page +
          `&rowsPerPage=` +
          rowsPerPage +
          `&sortBy=_id&sortOrder=desc&search=` +
          search + `&warehouse_id=${authData?.user_data?.warehouse_id}`
      )
      .then((response) => {
        setData(response?.data?.data || []);
        setCount(response?.data?.count || 0);
        setSpinner(false);
      });
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, count, search]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const deleteRecord = (row) => {
    setSelected(row);
    setShowConfirm(true);
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleDelete = () => {
    setShowConfirm(false);
    setSpinner(true);
    api
      .delete(`/laundryServices/${selected?._id}`)
      .then((res) => {
        // toast("Record deleted successfully", {
        //   type: "success",
        //   autoClose: 2000,
        // });
        getData();
      })
      .catch((error) => {
        setSpinner(false);
        // return toast(
        //   error?.response?.data?.message || "Something went wrong!",
        //   {
        //     type: "error",
        //     autoClose: 2000,
        //   }
        // );
      });
  };

  return (
    <>
      <div className="right-head-section">
        <h6 className="page-heading">Services List</h6>
      </div>
      <div className="outer-section" style={{ width: "100%" }}>
        <div className="list_main_div">
          <div className="list_inner_title_div">
            <div className="list_search_filter">
              {/* <CssTextField
                className="list_search_bar"
                id="new-menu"
                placeholder="search"
                variant="outlined"
                fullWidth
                onKeyUp={handleSearch}
                InputProps={{
                  startAdornment: (
                    <IconButton className="search_icon">
                      <img src={serachIcon} alt="search" />
                    </IconButton>
                  ),
                }}
              /> */}
              {/* {props?.menu_permissions?.ADD && ( */}
              {/* <Link className="margin-auto" to={`/services/form/-1`}>
                <Button
                  className="list_add_new_merchant"
                  variant="contained"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                >
                  Add New
                </Button>
              </Link> */}
              {/* )} */}
            </div>
          </div>
          {spinner ? (
            <div className="no_data_found loader_data_tbl">
              <CircularProgress style={{ color: "#1058ff" }} />
            </div>
          ) : (
            <>
              <TableContainer className="table_container">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="data_tbl_head_row">
                    <TableRow style={{ background: "none" }}>
                      <TableCell>Name</TableCell>
                      <TableCell>Background</TableCell>
                      <TableCell>Code</TableCell>
                      <TableCell>Amount(Per Kg)</TableCell>
                      <TableCell>Amount(Per Piece)</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="data_tbl_body_row">
                    {data.map((row, index) => (
                      <TableRow key={row?._id} style={{ background: "none" }}>
                        <TableCell>
                          {props?.menu_permissions?.EDIT ? (
                            <Link
                              to={`/services/form/${row._id}`}
                              style={{
                                textDecoration: "none",
                                color: "#1058ff",
                              }}
                            >
                              {row.name}
                            </Link>
                          ) : (
                            row?.name
                          )}
                        </TableCell>
                        <TableCell>{row.background_color}</TableCell>
                        <TableCell>{row.code}</TableCell>
                        <TableCell>
                          {Number(row?.amount || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(row?.piece_amount || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(row?.discount || 0).toFixed(2)}%
                        </TableCell>
                        <TableCell>
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/api/image/show/${row.image_url}`}
                            style={{ width: "25%", "margin-top": "15px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <Link to={`/services/form/${row._id}`}>
                            <IconButton
                              className="tbl_edit_icon"
                              aria-label="more"
                              id="long-button"
                              aria-controls="long-menu"
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                            >
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </Link>

                          <IconButton
                            className="list-delete-btn"
                            onClick={(e) => {
                              deleteRecord(row);
                            }}
                            variant="contained"
                            title="Detail"
                          >
                            <DeleteSweepIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="table_pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>

      <BootstrapDialog
        className="order_detail_refund_popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showConfirm}
      >
        <DialogContent>
          <Typography className="order_detail_refund_popup_title" gutterBottom>
            {
              <>
                {" "}
                <p>Confirm Delete</p>
                <span>Are you sure want to delete this Record?</span>
                <div className="order_detail_refund_popup_btn">
                  <Button
                    className="refund_save_btn"
                    variant="contained"
                    autoFocus
                    fullWidth
                    onClick={handleDelete}
                  >
                    Confirm
                  </Button>
                  <Button
                    className="refund_cancel_btn"
                    variant="contained"
                    autoFocus
                    onClick={handleClose}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </div>
              </>
            }
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ServicesList;

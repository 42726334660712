import React, { useEffect, useState, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import api from "../../../apis/api";
import "./index.css";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ToastContext from "../../../components/toast/toastContext";

const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ServicesForm(props) {
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);

  const { id } = useParams("id");
  const [data, setData] = React.useState({
    name: "",
    country_code: "91",
    mobile_no: "91",
    email_id: "",
    role: "",
    vehicle_id: "",
    vehicle_number: "",
  });
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [update, setupdate] = useState(false);

  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });

  useEffect(() => {
    if (id == -1) {
      setData({
        name: "",
      });
      // getServicesLocation();
    } else {
      api.get(`/laundryServices/${id}`).then((response) => {
        setData(response?.data?.data || {});
      });
    }
  }, []);

  const save = async () => {
    setData({ ...data });
    if (id == -1) {
      api
        .post(`/laundryServices`, data)
        .then((response) => {
          setupdate(!update);
          toastMessage("success", "Services Added Successfully.");
          navigate("/services/list");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toastMessage(
            "error",
            err?.response?.data?.message || "Something went wrong!"
          );
        });
    } else {
      api
        .put(`/laundryServices/${id}`, data)
        .then((response) => {
          setupdate(!update);
          toastMessage("success", "Services Updated Successfully.");
          navigate("/services/list");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          return toastMessage(
            "error",
            err?.response?.data?.message || "Something went wrong!"
          );
        });
    }
  };

  const openGoogleMap = () => {
    setShowMap(true);
  };

  const closeGoogleMap = () => {
    setShowMap(false);
  };

  const onSubmitAddress = async () => {
    api
      .post(`/google/full-address`, {
        lat: center?.lat,
        lng: center?.lng,
      })
      .then((response) => {
        setData({
          ...data,
          address: response.data.location,
          lat: center?.lat,
          lng: center?.lng,
        });
        setShowMap(false);
      });
  };

  const onChangeInput = (val, key) => {
    setData({
      ...data,
      [key]: val,
    });
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="right-head-section">
        <h6 className="page-heading">Services Form</h6>
      </div>
      <div className="outer-section" style={{ width: "100%" }}>
        <div className="merchant_list_main_div">
          <div style={{ padding: "15px" }}>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : (
              <>
                <Grid container spacing={2} className="merchant_main_grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Name</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Name"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.name}
                      required
                      onChange={(e) => onChangeInput(e.target.value, "name")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Background Color</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Background Color"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.background_color}
                      required
                      onChange={(e) =>
                        onChangeInput(e.target.value, "background_color")
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Code</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Code"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.code}
                      required
                      onChange={(e) => onChangeInput(e.target.value, "code")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Per Kg Amount</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Amount"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.amount}
                      required
                      type="number"
                      onChange={(e) => onChangeInput(e.target.value, "amount")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Per Piece Amount</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Amount"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.piece_amount}
                      required
                      type="number"
                      onChange={(e) =>
                        onChangeInput(e.target.value, "piece_amount")
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <p>Discount In Percent</p>
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Discount"
                      fullWidth
                      size="small"
                      min={0}
                      max={100}
                      value={data?.discount}
                      required
                      type="number"
                      onChange={(e) =>
                        onChangeInput(e.target.value, "discount")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className="sep_inpt_sec">
                      <p>Description</p>

                      <div className="inp_sep_div">
                        <CssTextArea
                          id="text"
                          variant="outlined"
                          sx={{
                            width: "100% !important",
                            borderColor: "#c4c4c4",
                            p: "10px",
                            borderRadius: "5px",
                            fontSize: "14px",
                          }}
                          placeholder="Description"
                          value={data?.description}
                          onChange={(e) =>
                            onChangeInput(e.target.value, "description")
                          }
                          minRows={2}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}

            {!enablemerchant && !loading ? (
              <div className="merchant_edit_button_dev">
                <Link to={`/services/list`}>
                  <Button
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  style={{ marginLeft: "15px" }}
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  Update
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div>
          <BootstrapDialog
            className="google-address-dialog"
            onClose={closeGoogleMap}
            aria-labelledby="customized-dialog-title"
            open={showMap}
          >
            <DialogContent>
              {/* <GoogleAddress
                onSubmitAddress={onSubmitAddress}
                center={center}
                setCenter={setCenter}
              /> */}
            </DialogContent>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
}

export default ServicesForm;
